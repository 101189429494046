<template>
  <div class="fileinput text-center">
    <div
      class="fileinput-new thumbnail"
      :class="{ 'img-circle': type === 'avatar' }"
    >
      <img :src="image" height="100px" alt="" />
    </div>
    <div>
      <span class="btn btn-info btn-simple btn-file">
        <span class="fileinput-new">{{
          fileExists ? changeText : selectText
        }}</span>
        <input type="hidden" value="" name="" />
        <input
          accept="image/*"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="true"
          aria-invalid="false"
        />
      </span>
      <base-button v-if="fileExists" @click="savePicture" class="btn btn-info btn-simple" type="success">
        {{ removeText }}
      </base-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'image-upload',
  props: {
    type: {
      type: String,
      default: '',
      description: 'Image upload type (""|avatar)'
    },
    src: {
      type: String,
      default: '',
      description: 'Initial image to display'
    },
    selectText: {
      type: String,
      default: 'Select image'
    },
    changeText: {
      type: String,
      default: 'Change'
    },
    removeText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      imagePreview: null
    };
  },
  computed: {
    fileExists() {
      return this.imagePreview !== null;
    },
    image() {
      return this.imagePreview || this.src || this.placeholder;
    }
  },
  methods: {
    handlePreview(event) {
      let file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(file);
      this.$emit("child-change", file);
    },
    savePicture() {
      this.imagePreview = null;
      this.$emit("child-save", null);
    }
  }
};
</script>
<style></style>
