<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          
        </li>
      </ul>
      <div class="copyright">
        
        <a
            href="https://darknoise.co"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            &copy; {{ year }} Dark Noise
          </a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
