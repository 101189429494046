<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="('optio')"
      :title="('optio')"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: ('dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('search'),
            icon: 'tim-icons icon-zoom-split',
            path: '/search'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add event'),
            icon: 'tim-icons icon-sound-wave',
            path: '/addevent/' + randomNumber
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add report'),
            icon: 'tim-icons icon-paper',
            path: '/addreport/' + randomNumber
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add person'),
            icon: 'tim-icons icon-single-02',
            path: '/addsoul/' + randomNumber
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add organisation'),
            icon: 'tim-icons icon-molecule-40',
            path: '/addorganisation/' + randomNumber
          }"
        >
        </sidebar-item>
        
        <sidebar-item
          :link="{
            name: ('add tribe'),
            icon: 'tim-icons icon-components',
            path: '/addtribe/' + randomNumber
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add specific location'),
            icon: 'tim-icons icon-square-pin',
            path: '/addspecificlocation/' + randomNumber
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: ('add source'),
            icon: 'tim-icons icon-bulb-63',
            path: '/addSource/' + randomNumber
          }"
        >
        </sidebar-item>
        <div v-if="$can('export', 'record')">
        <sidebar-item
          :link="{
            name: ('export'),
            icon: 'tim-icons icon-notes',
            path: '/export/' + randomNumber
          }"
        >
        </sidebar-item>
        </div>
        





      </template>
    </side-bar>

    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import ability from '@/config/ability';
import defineAbilityFor from '@/config/ability'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackground: 'blue', //vue|blue|orange|green|red|primary
      randomNumber: '123',
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
    this.randomNumber = Math.floor(Math.random() * 100000000001)
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
