var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":('optio'),"title":('optio')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: ('dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('search'),
          icon: 'tim-icons icon-zoom-split',
          path: '/search'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add event'),
          icon: 'tim-icons icon-sound-wave',
          path: '/addevent/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add report'),
          icon: 'tim-icons icon-paper',
          path: '/addreport/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add person'),
          icon: 'tim-icons icon-single-02',
          path: '/addsoul/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add organisation'),
          icon: 'tim-icons icon-molecule-40',
          path: '/addorganisation/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add tribe'),
          icon: 'tim-icons icon-components',
          path: '/addtribe/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add specific location'),
          icon: 'tim-icons icon-square-pin',
          path: '/addspecificlocation/' + _vm.randomNumber
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: ('add source'),
          icon: 'tim-icons icon-bulb-63',
          path: '/addSource/' + _vm.randomNumber
        }}}),(_vm.$can('export', 'record'))?_c('div',[_c('sidebar-item',{attrs:{"link":{
          name: ('export'),
          icon: 'tim-icons icon-notes',
          path: '/export/' + _vm.randomNumber
        }}})],1):_vm._e()],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }