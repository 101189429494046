
import Vue from 'vue';
import VueRouter from 'vue-router';
//import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
//import awswebclient from "@/aws-exports";
import { abilitiesPlugin } from '@casl/vue';
//import ability from './config/ability';
import defineAbilityFor from '@/config/ability'
//import getCurrentUser from '@/config/user'
//import { Auth } from '@aws-amplify/auth'
//import awswebclient from './aws-exports'
//import Amplify, * as AmplifyModules from "aws-amplify";
//import { AmplifyPlugin } from "aws-amplify-vue";

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })



// Amplify.configure({
//   "Auth": {
//     "region": process.env.VUE_APP_REGION,
//     "identityPoolId":process.env.VUE_APP_IDENTITY_POOL_ID,
//     "userPoolId": process.env.VUE_APP_USER_POOL_ID,
//     "userPoolWebClientId": process.env.VUE_APP_USER_POOL_CLIENT_ID,
//     "cookieStorage": {
//       "domain": process.env.VUE_APP_DOMAIN,
//       "expires": 0.2,
//       "sameSite": "lax",
//       "secure": true
//   }
//   }
// })

// Auth.configure({
//   "Auth": {
//     "region": process.env.VUE_APP_REGION,
//     "identityPoolId":process.env.VUE_APP_IDENTITY_POOL_ID,
//     "userPoolId": process.env.VUE_APP_USER_POOL_ID,
//     "userPoolWebClientId": process.env.VUE_APP_USER_POOL_CLIENT_ID,
//     "cookieStorage": {
//       "domain": process.env.VUE_APP_DOMAIN,
//       "expires": 0.2,
//       "sameSite": "lax",
//       "secure": true
//   }
//   }
// })

// Auth.configure({
//   "Auth": {
//     "region": "ap-southeast-2",
//     "identityPoolId":"ap-southeast-2:dd991618-5fc2-4d3e-ad1b-73b5f89275b5",
//     "userPoolId": "ap-southeast-2_Md4x2s3EE",
//     "userPoolWebClientId": "1q0rvoohjgtrvfd02ghlf8rfib",
//     "cookieStorage": {
//         "domain": "localhost:8080",//"blackwhitenoise.net",
//         "expires": 0.2,
//         "sameSite": "lax",
//         "secure": true
//     }
//   }
// })


// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
// Vue.use(AmplifyPlugin, AmplifyModules);


// let isUserAdmin = true
// //console.log("isuseradmin? " + isUserAdmin)
// //let currentUser = {isAdmin: isUserAdmin}
// if (window.GLOBALUSER == "admin") {
//   isUserAdmin = true
// }
let currentUser = {isAdmin: false}
let ability = defineAbilityFor(currentUser)
Vue.use(abilitiesPlugin, ability);
//console.log(window.GLOBALUSERTYPE)
//Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
});
