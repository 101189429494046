import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import LoginPage from 'src/pages/Login/Login.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Optio Pages
const Search = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/search/search.vue');
const addReport = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/reports/addReportWizard.vue');
const addSoul = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/souls/addSoulWizard.vue');
const addOrganisation = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/organisations/addOrganisationWizard.vue');
const addEvent = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/events/addEventWizard.vue');
const viewEvent = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/events/viewEvent.vue');
const viewOrganisation = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/organisations/viewOrganisation.vue');
const viewSoul = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/souls/viewSoul.vue');
const UserProfile = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/user/UserProfile.vue');
const viewReport = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/reports/viewReport.vue'); 
const addTribe = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/tribes/addTribeWizard.vue');
const addSpecificLocation = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/specificlocations/addSpecificLocationWizard.vue');
const addSource = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/sources/addSourceWizard.vue');
const viewSource = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/sources/viewSource.vue');
const viewTribe = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/tribes/viewTribe.vue');
const viewSpecificLocation = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/specificlocations/viewSpecificLocation.vue');
const link = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/links/link.vue');
const exportWizard = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/export/exportWizard.vue');
const login = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/Login/Login.vue');
const profile = () =>
import(/* webpackChunkName: "extra" */ 'src/pages/user/UserProfile.vue');

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');





const routes = [
  {
    path: '/login',
    props: true,
    //component: LoginPage,
    name: 'Login',
    components: { default: login}
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: {requiresAuth: true},
    redirect: '/login',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard }
      },
      {
        path: '/userProfile',
        name: 'Profile',
        components: {default: UserProfile}
      },
      {
        path: '/link/:id',
        name: 'Link',
        components: {default: link}
      },
      {
        path: '/viewReport/:id',
        name: 'View Report',
        components: {default: viewReport}
      },
      {
        path: '/addSource/:id',
        name: 'Add Source',
        components: {default: addSource}
      },
      {
        path: '/viewSource/:id',
        name: 'View Source', 
        components: {default: viewSource}
      },
      {
        path: '/viewTribe/:id',
        name: 'View Tribe', 
        components: {default: viewTribe}
      },
      {
        path: '/search',
        name: 'Search',
        components: { default: Search }
      },
      {
        path: '/addReport/:id',
        name: 'Add Report',
        components: { default: addReport }
      },
      {
        path: '/addSoul/:id',
        name: 'Add Person',
        components: { default: addSoul }
      },
      {
        path: '/addOrganisation/:id',
        name: 'Add Organisation',
        components: { default: addOrganisation }
      },
      {
        path: '/addEvent/:id',
        name: 'Add Event',
        components: { default: addEvent }
      },
      {
        path: '/viewEvent/:id',
        name: 'View Event',
        components: { default: viewEvent }
      },
      {
        path: '/viewOrganisation/:id',
        name: 'View Organisation',
        components: { default: viewOrganisation }
      },
      {
        path: '/viewSoul/:id',
        name: 'View Person',
        components: { default: viewSoul }
      },
      {
        path: '/addTribe/:id',
        name: 'Add Tribe',
        components: { default: addTribe }
      },
      {
        path: '/addSpecificLocation/:id',
        name: 'Add Specific Location',
        components: { default: addSpecificLocation }
      },
      {
        path: '/viewSpecificLocation/:id',
        name: 'View Specific Location',
        components: { default: viewSpecificLocation }
      },
      {
        path: '/export/:id',
        name: 'Export',
        components: { default: exportWizard }
      },
      {
        path: '/profile',
        name: 'User Profile',
        components: { default: profile }
      },
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
