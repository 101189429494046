<template>
  <div class="front-door">

  </div>
</template>

<script>
import Vue from 'vue';
import { abilitiesPlugin } from '@casl/vue';
import defineAbilityFor from '@/config/ability'
import axios from 'axios'
import UserService from '@/services/UserService'
import SearchService from '@/services/SearchService'
import { Loading } from 'element-ui';
import { AbilityBuilder } from '@casl/ability';

export default {
  name: "Login",
  data() {
    return {
    }
  },
  mounted() {
    this.loginRedirect()
  },
  methods: {
    async loginRedirect() {
      let loading = Loading.service()
      // Retrieve Authorisation Grant from the URL
      let urlParams = new URLSearchParams(window.location.search);
      let authGrant = urlParams.get('code')

      if (authGrant != null) {
        // Call the /oauth2/token endpoint to get jwt
        await this.getToken(authGrant)
        await this.getUser()
        await this.getNations()
        loading.close()
        this.$router.push({ name: 'Search'})
      } else {
        // Failed login or direct call -> go to authentication
        window.location.assign(process.env.VUE_APP_AUTH_URL)
      }

    },
    async getToken(userCode) {
      // Set the body of the request
      const params = {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_USER_POOL_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_REDIRECT_URI,
        code: userCode,
      }

      const data = Object.entries(params)
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
          .join('&');

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data,
        url: process.env.VUE_APP_OAUTH_URL,
      };

      const tokenResponse = await axios(options);  // wrap in async function
      global.GLOBALIDTOKEN = tokenResponse.data.id_token // ID token for auth headers
      window.GLOBALACCESSTOKEN = tokenResponse.data.access_token // access token for user details
    },
    async getNations() {
      const activeNations = await SearchService.fetchNations()
      window.GLOBALNATIONS = activeNations.data
    },
    async getUser() {
      let bearerToken = 'Bearer ' + window.GLOBALACCESSTOKEN
      const options = {
        method: 'GET',
        headers: { 'Authorization': bearerToken },
        url: process.env.VUE_APP_USERINFO_URL,
      };

      const userResponse = await axios(options);  // wrap in async function
      
      let userFromMongo = await UserService.getUser({sub: userResponse.data.sub}) // get user from Mongo
      if (userFromMongo.data.length == 0) {
        await UserService.addUser({sub: userResponse.data.sub, name: userResponse.data.username, email: userResponse.data.email, country: "", userType: "user"})
        userFromMongo = await UserService.getUser({sub: userResponse.data.sub}) 
      }
      window.GLOBALUSER = await userFromMongo.data[0]
      window.GLOBALUSERNAME = await window.GLOBALUSER["name"]
      window.GLOBALCOUNTRY = await window.GLOBALUSER["country"]
      window.GLOBALUSERID = await window.GLOBALUSER["_id"]
      window.GLOBALUSERTYPE = await window.GLOBALUSER["userType"]

      // log in as standard - so only update if user is admin
      if (window.GLOBALUSERTYPE == "admin") {
        this.updateAbility()
      }

      
      
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder();
        can('update', 'all')
        can('export', 'all')
        can('delete', 'all')
      this.$ability.update(rules);
    }
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
