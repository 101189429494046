import Api from '@/services/Api'


const options = {
  headers: {'Authorization': window.GLOBALIDTOKEN}
}

export default {
  
  fetchEvents (params) {
    return Api().get('search/basic/events/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchOrganisations (params) { 
    return Api().get('search/basic/organisations/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchReports () {
    return Api().get('search/basic/reports', {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchNations () {
    return Api().get('search/basic/nations' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchLocations (params) {
    return Api().get('search/basic/locations/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchPOIs () {
    return Api().get('search/basic/pois' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchTribes (params) {
    return Api().get('search/basic/tribes/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchSources (params) {
    return Api().get('search/basic/sources/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  fetchSouls (params) {
    return Api().get('search/basic/souls/' + params.country + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchReports (params) {
    return Api().get('search/reports/' + params.must + '/' + params.mustNot + '/' + params.should + '/' + params.fuzziness + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchTribes (params) {
    return Api().get('search/tribes/' + params.searchTerms + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchEvents (params) {
    return Api().get('search/events/' + params.country + '/' + params.must + '/' + params.mustNot + '/' + params.should + '/' + params.fuzziness + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchSouls (params) {
    return Api().get('search/souls/' + params.country + '/' + params.must + '/' + params.mustNot + '/' + params.should + '/' + params.fuzziness + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchOrganisations (params) {
    return Api().get('search/organisations/' + params.country + '/' + params.must + '/' + params.mustNot + '/' + params.should + '/' + params.fuzziness + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  validateSoul (params) {
    return Api().get('search/validate/soul/' + params.country + '/' + params.position + '/' + params.englishName + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  validateOrganisation (params) {
    return Api().get('search/validate/organisation/' + params.country + '/' + params.englishName + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchGeneralLocations (params) {
    return Api().get('search/generallocations/' + params.searchTerms + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchSpecificLocations (params) {
    return Api().get('search/specificlocations/' + params.searchTerms + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  searchMainSpecificLocations (params) {
    return Api().get('search/mainspecificlocations/' + params.must + '/' + params.mustNot + '/' + params.should + '/' + params.fuzziness + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getCountSouls () {
    return Api().get('search/count/souls' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getCountOrganisations () {
    return Api().get('search/count/organisations' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getCountEvents () {
    return Api().get('search/count/events' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getCountTribes () {
    return Api().get('search/count/tribes' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  getCountReports () {
    return Api().get('search/count/reports' + '/' + window.GLOBALUSERNAME, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
}