import Vue from "vue";
import VueRouter from 'vue-router';
import routes from './routes';
import Amplify from "aws-amplify";

// configure router
const router = new VueRouter({
  routes: routes, 
  mode: 'history',
  //linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      if (window.GLOBALIDTOKEN != null) {
        next();
      } else{
        window.location.assign(process.env.VUE_APP_AUTH_URL)
      }
      
    } catch (e) {
      window.location.assign("http://www.google.com")

    }
  }
  next();
});

export default router;
