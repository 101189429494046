import { defineAbility } from '@casl/ability';

//export default defineAbility((can, cannot) => {
//  can('manage', 'all');
//  cannot('export', 'all');
//});


export default function defineAbilityFor(user) {
    return defineAbility((can, cannot) => {
        can('manage', 'all')
        cannot('update', 'mandatory')
        cannot('export', 'all')
        cannot('delete', 'all')

        if (user.isAdmin) {
            can('update', 'all')
            can('export', 'all')
            can('delete', 'all')
        }
    })
}



