import Api from '@/services/Api'

const options = {
  headers: {'Authorization': global.GLOBALIDTOKEN}
}

export default {
  getUser (params) {
    return Api().get('user/get/' + params.sub, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  saveUser (params) {
    return Api().post('user/update/' + params.id, params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },
  addUser (params) {
    return Api().post('user/add', params, {headers: {'Authorization': global.GLOBALIDTOKEN}})
  },


}